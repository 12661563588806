import {
  AgAirline,
  AgInput,
  AgSelect,
  AgSelect1,
  AgUnloc
} from "../components/AgComponents.js";
import Api from "../state/Api.js";
import { Columns, Col, Col2, Col3, Col4, Col6, Col12 } from "../Controls.js";
import {
  getStaticConsolidatorList,
  commodityList,
  hazardousList,
} from "../StaticData.js";
import {
  configStore,
  quoteDataRec,
  chargesStore1,
  airChargesStore,
  customerStore,
  entityStore,
  aguserStore,
  multiSelectListStore,
  dataListStore,
  modalStore,
  EntityV2Store,
  AgmodalStore,
  quoteDataRec_inti,
  loginStore,
} from "../state/CommonState.js";
import { containerStore, cargoStore } from "../state/CommonState.js";
import {
  incoTermsList,
} from "../StaticData.js";
import { proxy, useSnapshot } from "valtio";
import { chargesData1 } from "../StaticData.js";
import { fclChargesStore } from "../state/CommonState.js";
import Utils from "../util/Utils.js";
import AgCustlist, { AgSelectNew } from "../components/AgCustlist.js";
import { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
  AgMultiSelectWithCheckBoxes,
  AgSelectWithMap,
} from "../components/AgCustomComponents.js";
import {
  chargesValidationStore,
  QuotationValidationStore,
} from "../state/validation-stores.js";
import { SHIPMENT_SCOPE } from "../util/quotationUtil.js";
import AguserApi from "../state/AguserApi.js";
import LCLChargesCompv3 from "./lcl-charges-v3.js";
import AirChargesCompv3 from "./air-charges-v3.js";
import FCLChargesCompv3 from "./fcl-charges-v3.js";
import VehicleViewv3 from "./vehicle-view-v3.js";
import RoleUtils from "../util/RoleUtils.js";
import { PERMISSIONS } from "../util/constants.js";
import { taskStore } from "../mytasks/task-store.js";
import EntityV2Api from "../state/entities-api.js";
import { useForm } from "react-hook-form";
import { Quotation } from "../state/Types.js";
import { Cargo, Container } from "../enquiry/enquiry-data.js";
import { ActiveTab } from "../v3-componets/AgActiveTab.js";
import { Route } from "../v3-componets/AgRoute.js";
import { Details } from "../v3-componets/AgDetails.js";
import { Headspan } from "../v3-componets/AgHeadspan.js";
import { Card } from "reactstrap";
import ship1 from "../assets/images/ship1.svg";


export default function Quotev3() {
  useSnapshot(configStore);
  useSnapshot(customerStore);
  useSnapshot(multiSelectListStore);
  useSnapshot(dataListStore);
  useSnapshot(modalStore);
  useSnapshot(entityStore);
  useSnapshot(loginStore)
  useSnapshot(aguserStore)
  
useSnapshot(EntityV2Store)
useSnapshot(taskStore)
let rec1 = useSnapshot(quoteDataRec)
const rec = useSnapshot(quoteDataRec_inti)
useSnapshot(QuotationValidationStore);
  const selectedConsolidatorList = getStaticConsolidatorList();
  const consolidatorListKey = "consolidatorList";
  const [cursor, setCursor] = useState(0);
  const ref = useRef(null);
  const [entitieslist, setEntitieslist] = useState([]);
  const [prList, setPrList] = useState([])
  const [prListglobal, setPrListglobal] = useState([])
  const [rmcs, setRmcs] = useState([])
  const [agActiveList, setAgActiveList] = useState([])
  const addprlist = (e) =>{
    if(e.length){
      if(JSON.stringify(rmcs)!=JSON.stringify(e)){
        setRmcs(e)
      }
      let l = []
      let k = []
      for (let j = 0; j < prList.length; j++) {
        k.push(prList[j]['value'])
        l.push(prList[j])
      }
      for (let i = 0; i < e.length; i++) {
        if(e[i]!=undefined && e[i].email != undefined && k.indexOf(e[i].email)==-1){
          k.push(e[i].email)
          l.push(
            {
              label:`${e[i]['firstName']} ${e[i]['lastName']} (${e[i]['email']})`,
              value:e[i]['email']
            }
          )
        }
      }
      setPrListglobal(l)
    }else{
      if(JSON.stringify(prList)!=JSON.stringify(prListglobal)){
        setPrListglobal(prList)
      }
    }
  }
  useLayoutEffect(() => {
    let list = []
    if(quoteDataRec.isfromAgragaEnquiry == 'Yes'){
      let v2StoreList = EntityV2Store.list
      for (let i = 0; i < v2StoreList.length; i++) {
        if(v2StoreList[i]['entityName'] === 'YET TO BE ASSIGNED' || v2StoreList[i]['entityType'] === 'Customer'){
          list.push(v2StoreList[i])
        }
      }
    }else{
      for (let i = 0; i < EntityV2Store.Cuslist.length; i++) {
        if(EntityV2Store.Cuslist[i]['entityName'] !='YET TO BE ASSIGNED'){
          list.push(EntityV2Store.Cuslist[i])
        }
      }
    }
    if(JSON.stringify(EntityV2Store.list)!=list){
      setEntitieslist(list)
    }
  },[quoteDataRec.isfromAgragaEnquiry,EntityV2Store.list])
  const getExchangerates = async( ) =>{
    await Api.getExchangerates()
  }
  useEffect(()=>{
    const agactiveuserlist = () =>{
      let l = []
      for (let i = 0; i < aguserStore.list.length; i++) {
        let e = aguserStore.list[i]
        if(e['status']=="ACTIVE" && e['email'].indexOf("@agraga.com")!=-1){
          l.push(
            {
              label:`${e['firstName']} ${e['lastName']} (${e['email']})`,
              value:`${e['firstName']} ${e['lastName']} (${e['email']})`
            }
          )
        }
      }
      if(JSON.stringify(agActiveList)!=JSON.stringify(l)){
        setAgActiveList(l)
      }
    }
    agactiveuserlist()
  },[aguserStore.list])
  useEffect(() => {
    console.log("Inside useEffect of Quote ");
    getExchangerates()
    Api.getFCLCarrierList();
    AguserApi.getList();
  }, []);
  useEffect(() => {
    const pruserlist = (data) =>{
      let l = []
      let user = false
      for (let i = 0; i < data.length; i++) {
        if(loginStore?.userRec?.email.trim()==data[i]['email'].trim()){
          user = true
        }
        l.push(
          {
            label:`${data[i]['firstName']} ${data[i]['lastName']} (${data[i]['email']})`,
            value:data[i]['email']
          }
        )
      }
      if(!user){
        l.push(
          {
            label:`${loginStore?.userRec?.firstName} ${loginStore?.userRec?.lastName} (${loginStore?.userRec?.email})`,
            value:loginStore?.userRec?.email
          }
        )
      }
      if(JSON.stringify(prList)!=JSON.stringify(l)){
        setPrList(l)
        setPrListglobal(l)
        if(rmcs.length>0){
          addprlist(rmcs)
        }
      }
    }
    if(quoteDataRec && quoteDataRec.shipmentType){
      switch(true){
        case quoteDataRec.shipmentType.toLowerCase().indexOf("fcl") >= 0:
          AguserApi.getUsers4role_new("FCL Procurement", pruserlist)
          break
        case quoteDataRec.shipmentType.toLowerCase().indexOf("lcl") >= 0:
          AguserApi.getUsers4role_new("LCL Procurement", pruserlist)
          break
        case quoteDataRec.shipmentType.toLowerCase().indexOf("air") >= 0:
          AguserApi.getUsers4role_new("AIR Procurement", pruserlist)
          break
      }
    }
  }, [quoteDataRec.shipmentType]);
  useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor]);
  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    quoteDataRec.customTC = e.target.value;
  };
  useLayoutEffect(() => {
    if (
      quoteDataRec?.shipmentType != "" &&
      quoteDataRec?.shipmentType.toLowerCase().includes("lcl")
    ) {
      if (!quoteDataRec?.quotationNum && quoteDataRec?.consolidatorList == null)
        quoteDataRec.consolidatorList = selectedConsolidatorList;
      multiSelectListStore.selectList[consolidatorListKey] =
        selectedConsolidatorList.map(({ consolidatorAbbrName,scacCode }) => ({
          id: consolidatorAbbrName,
          name: consolidatorAbbrName,
          scacCode:scacCode,
          checked: quoteDataRec.consolidatorList
            .map((addedConsolidator) => addedConsolidator.consolidatorAbbrName)
            .includes(consolidatorAbbrName),
        }));
    } else quoteDataRec.consolidatorList = null;
  }, [quoteDataRec.consolidatorList, quoteDataRec.shipmentType]);

  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: quoteDataRec,
      dataList,
    };
  };


  const portOfReceiptLable =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of Receipt"
      : "Place of Receipt";
  const portOfLoadingLabel =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of departure"
      : "* Port of Loading";
  const portOfDischargeLabel =
    quoteDataRec.shipmentType.indexOf("Air") >= 0
      ? "* Airport of Arrival"
      : "Port of Discharge";

  let buttontext = 'Save'
  if(quoteDataRec.status == 'DRAFT(Enquiry)'){
    buttontext = 'SUBMIT'
  }else{
    buttontext = 'Save'
  }
  useEffect(()=>{
    if(quoteDataRec.scac!=undefined && quoteDataRec.scac.length>0 && quoteDataRec?.shipmentType.toLowerCase().includes("fcl")){
      for (let i = 0; i < dataListStore["FCLCarrierList"].length; i++) {
        let l = dataListStore["FCLCarrierList"][i]
        if(l["SCAC code"] == quoteDataRec.scac){
          quoteDataRec.carrier = `${l["Display Name"]} (${l["SCAC code"]})`
        }
      }
    }
  },[JSON.stringify(dataListStore["FCLCarrierList"]),JSON.stringify(quoteDataRec)])
  useEffect(() => {
    getEntityData()
    console.log("quoteDataRec",quoteDataRec);
  }, [quoteDataRec.entityId])
  const getEntityData = async ()=>{  
    if (!quoteDataRec.entityId) {
      return ""
    }
    let entity =  await EntityV2Api.viewEntity(quoteDataRec.entityId)
    console.warn("FOUND ENTITY = ", entity);
    if (entity) {
      let k = []
      let email = entity?.customer?.crossBorder?.relationshipManager;
      if(email){
        k.push(aguserStore.getdetails(email))
        quoteDataRec.salesperson = aguserStore.getName4email(email);
      }
      else {
        quoteDataRec.salesperson = "";
      }
      email = entity?.customer?.crossBorder?.customerService;
          if (email){
            k.push(aguserStore.getdetails(email))
        quoteDataRec.customerService = aguserStore.getName4email(email);
          }else {
        quoteDataRec.customerService = "";
      }
      addprlist(k)
    }
  }
  const [selectedType, setType] = useState("LCL");
  const md = [
    {
        destination:"",
        cargoDetails:[new Cargo()],
    }
]
  const cargoForm = useForm({
      defaultValues: {
          mode: "LCL",
          orginScope: false,
          destinationScope: false,
          originType: false,
          destinationType: false,
          showCargoDetailsOutGuage: false,
          showPalletLabel:false,
          scope: SHIPMENT_SCOPE.P2P,
          multidest:md,
          containerDetails:[new Container()]
      }
  })
  const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState: { errors }, trigger, getFieldState,getValues } = cargoForm;
  useSnapshot(cargoStore)
  const [show, setShow] = useState(false);
  const [recdumb, setRecdumb] = useState({});
  const changeShipmentTypes = (type) =>{
    clearErrors()
    QuotationValidationStore.reset()
    setValue("isFba", false);
    if(type == 'FCL' && quoteDataRec.carrier.toLowerCase() == "agraga"){
      quoteDataRec.carrier = ""
    }
    if(type == 'LCL' && quoteDataRec.carrier.toLowerCase() != "agraga"){
      quoteDataRec.carrier = "Agraga"
    }
    if(quoteDataRec.quotationNum==undefined || quoteDataRec.quotationNum.length==0){
      cargoStore.resetNewCargo()
      containerStore.list = []
      cargoStore.cargoList = []
      const data1 = chargesData1[type.toLowerCase()];
      if (data1 && data1.initialLength) {
        if (type.indexOf("FCL") >= 0) {
          fclChargesStore.colNames = data1.colNames;
          fclChargesStore.initialLength = data1.initialLength;
          fclChargesStore.list = data1.preList;
          fclChargesStore.list = fclChargesStore.list.map((ele, index) => { 
            return { ...ele, currency: data1.preList[index].currency }
          })
        } else if (type.indexOf("Air") >= 0) {
          airChargesStore.initialLength = data1.initialLength;
          airChargesStore.list = data1.preList;
          airChargesStore.list = airChargesStore.list.map((ele, index) => { 
            return { ...ele, currency: data1.preList[index].currency }
          })
        } else {
          chargesStore1.initialLength = data1.initialLength;
          chargesStore1.list = data1.preList;
          chargesStore1.list = chargesStore1.list.map((ele, index) => { 
            return { ...ele, currency: data1.preList[index].currency }
          });
          quoteDataRec.carrier = "Agraga";
        }
      } else {
        fclChargesStore.initialLength = 0;
        fclChargesStore.list = [];
        chargesStore1.initialLength = 0;
        chargesStore1.list = [];
        airChargesStore.initialLength = 0;
        airChargesStore.list = [];
      }
    }
    setType(type)  
  }
useEffect(()=>{
  if(quoteDataRec.quotationNum == "" && configStore.disableShipmentType == ""){
  let quoteDataRec = proxy(new Quotation());
  if(selectedType == 'FCL'){
    quoteDataRec.shipmentType = `FCL (Factory/Factory)`;
    setValue("shipmentType","FCL (Factory/Factory)")
  }else{
    quoteDataRec.shipmentType = selectedType
    setValue("shipmentType",selectedType)
  }
    setValue("origin", "");
    setValue("multidest", md);
  }
  // setValue("stuffingType","Factory")
  // setValue("destuffingType","Factory")
  setValue("mode",selectedType);
  clearErrors();
},[selectedType])



const cargoReadinessDate = watch("cargoreadinessdate")
const shipmentScope = watch("scope")
const hazardous = watch("hazardous")
const shipmentType = watch("shipmentType")
const multidest = watch("multidest")
const cargoDimensionUnit = watch("cargoDimensionUnit")
const showCargoDetailsOutGuage = watch("showCargoDetailsOutGuage")
const stuffingType = watch("stuffingType")
const destuffingType = watch("destuffingType")
const mode = watch("mode")
const containerDetails = watch("containerDetails")
useEffect(()=>{
  
},[mode])
useEffect(()=>{
  if(quoteDataRec.quotationNum==undefined || quoteDataRec.quotationNum.length==0){
  if(quoteDataRec.shipmentType!=shipmentType && shipmentType!=undefined){
    quoteDataRec.shipmentType=shipmentType
  }
  let b = ["Factory","Factory"]
  if(quoteDataRec.shipmentType.indexOf("FCL")!=-1){
    const a = quoteDataRec.shipmentType;
    b = []
    b = a.match(/\(([^)]+)\)/)[1].split("/");
  }
  if(quoteDataRec.stuffingType!=stuffingType && stuffingType!=undefined && b[0]==stuffingType){
    quoteDataRec.stuffingType=stuffingType
  }
  if(quoteDataRec.destuffingType!=destuffingType && destuffingType!=undefined && b[1]==destuffingType){
    quoteDataRec.destuffingType=destuffingType
  }
}
},[shipmentType,stuffingType,destuffingType])
useEffect(()=>{
  if(show){
  if(quoteDataRec.cargoReadinessDate!=cargoReadinessDate && cargoReadinessDate!=undefined){
    quoteDataRec.cargoReadinessDate=cargoReadinessDate
  }
  if(quoteDataRec.shipmentScope!=shipmentScope && shipmentScope!=undefined){
    console.log(quoteDataRec.shipmentScope,shipmentScope)
    quoteDataRec.shipmentScope=shipmentScope
  }
  let h = hazardous==false || hazardous==undefined?"No":"Yes"
  if(quoteDataRec.hazardous!=h){
    quoteDataRec.hazardous=h
    quoteDataRec.commodityType = ""
  }
  let cargo = []
  let container = []
  let obj = {}
  let multidest_new = []
  if(multidest!=undefined && shipmentType!=undefined){
    for (let i = 0; i < multidest.length; i++) {
      let z = {}
      z['destination'] = multidest[i]['destination']
      z['cargoDetails'] = []
      for (let j = 0; j < multidest[i]['cargoDetails'].length; j++) {
        let k = multidest[i]['cargoDetails'][j]
        k = Utils.setCargo_unit(k,multidest[0]['cargoDetails'][0],['weight','chargeableWeight','volume','totalWeight','totalVolume','dimensions'],cargoDimensionUnit)
        // k['weight']['unit'] = multidest[0]['cargoDetails'][0]['weight']['unit']
        // k['chargeableWeight']['unit'] = multidest[0]['cargoDetails'][0]['chargeableWeight']['unit']
        // k['volume']['unit'] = multidest[0]['cargoDetails'][0]['volume']['unit']
        // k['totalWeight']['unit'] = multidest[0]['cargoDetails'][0]['totalWeight']['unit']
        // k['totalVolume']['unit'] = multidest[0]['cargoDetails'][0]['totalVolume']['unit']
        // k['dimensions']['unit'] = multidest[0]['cargoDetails'][0]['dimensions']['unit']
        let c = Utils.convert_calcCargoDetails(multidest[i]['cargoDetails'][j],shipmentScope,shipmentType,showCargoDetailsOutGuage,cargoDimensionUnit)
        z['cargoDetails'].push(c)
        cargo.push(c)
      }
      multidest_new.push(z)
  }
  if(JSON.stringify(multidest_new)!=JSON.stringify(quoteDataRec.multidest)){
    //quoteDataRec.multidest.length = 0
    quoteDataRec.multidest = JSON.parse(JSON.stringify(multidest_new));
  }
  if(containerDetails!=undefined){
    for (let j = 0; j < containerDetails.length; j++) {
      if(containerDetails[j]['containerType']!=undefined && containerDetails[j]['containerType'].length>0){
        let c = containerDetails[j]['containerType']
        if(obj[c]==undefined){
            obj[c] = {
                containerType : "",
                numContainers: 0,
                unit: "",
                wtPerContainer: 0
            }
        }
        obj[c]['containerType'] = c
        obj[c]['unit'] = 'Kgs'
        obj[c]['numContainers'] = obj[c]['numContainers']+Number(containerDetails[j]['numContainers'])
        obj[c]['wtPerContainer'] = Number(Utils.toUnit(containerDetails[j]['wtPerContainer'], containerDetails[0]['unit']))
      }
    }
  }
  let o = Object.keys(obj)
  for (let i = 0; i < o.length; i++) {
      container.push(obj[o[i]])
  }
  }
  if(JSON.stringify(containerStore.list)!=JSON.stringify(container)){
    containerStore.list = JSON.parse(JSON.stringify(container))
  }
  if(JSON.stringify(cargoStore.cargoList)!=JSON.stringify(cargo)){
    cargoStore.cargoList = JSON.parse(JSON.stringify(cargo))
    cargoStore.calculateCargoTotals()
  }
}
},[shipmentType,hazardous,shipmentScope,cargoReadinessDate,JSON.stringify(multidest),JSON.stringify(containerDetails)])

// useEffect(()=>{
//   setShow(false)
//   const setv = (key,key1="",val="") =>{
//     let l = getValues(key)
//     if(key1!="" && (rec[key1]!=l || l == undefined) && val==""){
//       console.log("+++0001",key,key1,rec[key1],l)
//       setValue(key,rec[key1])
//     }
//     if(val!=l && val!=""){
//       console.log("+++0002",key,key1,val,l)
//       setValue(key,val)
//     }
//   }
//   if(JSON.stringify(recdumb)!=JSON.stringify(rec)){
//     setRecdumb(rec)
//     if(rec.shipmentType.length>3 && rec.shipmentType.toLowerCase().indexOf('fcl')!=-1 && watch("mode")!="FCL"){
//       setv("mode","",'FCL');
//       if(selectedType!="FCL"){
//         setType("FCL")
//       }
//   }else if(watch("mode")!=rec.shipmentType && watch("mode")!="FCL"){
//     setv("mode","shipmentType",rec.shipmentType);
//       if(selectedType!=rec.shipmentType){
//         setType(rec.shipmentType)
//       }
//   }
//   setv("shipmentType","shipmentType",rec.shipmentType)
//   setv("scope","shipmentScope",rec.shipmentScope)
//   if(rec.multidest!=undefined){
//     let m = watch("multidest")
//     if(JSON.stringify(rec.multidest)!=JSON.stringify(watch("multidest")) && m!=undefined){
//       setValue("multidest",rec.multidest)
//     }
//   }
//   setv("origin","origin",rec.origin)
//   setv("cargoreadinessdate","cargoReadinessDate",rec.cargoReadinessDate)
//   setv("customerreference","customerReferenceNum",rec.customerReferenceNum)
//   setv("quoteClass","quoteClass",rec.quoteClass)
//   setv("packingGroup","packingGroup",rec.packingGroup)
//   setv("temperature","temperature",rec.temperature)
//   setv("cargoValue","cargoValue",rec.cargoValue)
//   setv("unnumber","unnumber",rec.unnumber)
//   setv("minTemperature","minTemperature",rec.minTemperature)
//   setv("maxTemperature","maxTemperature",rec.maxTemperature)
//   let stuffingtype = rec.stuffingType == undefined || rec.stuffingType == ""?"Factory":rec.stuffingType
//   let destuffingtype = rec.destuffingType == undefined || rec.destuffingType == ""?"Factory":rec.destuffingType
//   setv("stuffingType","stuffingtype",stuffingtype)
//   setv("destuffingType","destuffingtype",destuffingtype)
//   setv("cargoDimensionUnit","cargoDimensionUnit",rec.cargoDimensionUnit==undefined?"Cms":rec.cargoDimensionUnit)
//   setv("days","fbaDays",rec.fbaDays!=undefined?rec.fbaDays:0)
//   setValue("hazardous",rec.hazardous === "No" ? false :true)
//   setValue("nonStackable", rec.nonStackable === "No" ? false :true)
//   setValue("temperatureControlled", rec.temperatureControlled === "No" ? false :true)
//   setValue("isFba",rec.fba === "Yes" ? true :false)
//   setValue("ior",rec.fbaIorRequired === "Yes" ? true :false)
//   setValue("occ",rec.fbaOCC === "Yes" ? true :false)
//   setValue("dcc",rec.fbaDCC === "Yes" ? true :false)
//   setValue("survey",rec.fbaSurvey === "Yes" ? true :false)
//   setValue("storageAtDestination",rec.fbaSAD === "Yes" ? true :false)
//   setValue("palletizationandlabelling",rec.fbaPAL === "Yes" ? true :false)
//   setValue("pick_up_by_amazon",rec.pick_up_by_amazon === "Yes" ? true :false)
//   }
//   setShow(true)
// },[rec])

useEffect(()=>{
  setShow(false)
  setValue("fbaalways","Yes")
  if(JSON.stringify(recdumb)!=JSON.stringify(rec)){
    setRecdumb(rec)
    if(rec.shipmentType.length>3 && rec.shipmentType.toLowerCase().indexOf('fcl')!=-1){
      setValue("mode",'FCL');
      setType("FCL")
  }else{
      setValue("mode",rec.shipmentType);
      setType(rec.shipmentType)
  }
  if (quoteDataRec.shipmentType.toLowerCase().indexOf("lcl") != -1 && (quoteDataRec.carrier==undefined || quoteDataRec.carrier!='Agraga')) {
    quoteDataRec.carrier = "Agraga";
  }
  setValue("shipmentType",rec.shipmentType)
  setValue("scope",rec.shipmentScope)
  setValue("origin",rec.origin)
  setValue("cargoreadinessdate",rec.cargoReadinessDate)
  setValue("customerreference",rec.customerReferenceNum)
  setValue("hazardous",rec.hazardous === "No" ? false :true)
  setValue("quoteClass",rec.quoteClass)
  setValue("packingGroup",rec.packingGroup)
  setValue("temperature",rec.temperature)
  setValue("cargoValue",rec.cargoValue)
  setValue("unnumber",rec.unnumber)
  setValue("nonStackable", rec.nonStackable === "No" ? false :true)
  setValue("temperatureControlled", rec.temperatureControlled === "No" ? false :true)
  setValue("minTemperature",rec.minTemperature)
  setValue("maxTemperature",rec.maxTemperature)
  let stuffingtype = rec.stuffingType == undefined || rec.stuffingType == ""?"Factory":rec.stuffingType
  let destuffingtype = rec.destuffingType == undefined || rec.destuffingType == ""?"Factory":rec.destuffingType
  setValue("stuffingType",stuffingtype)
  setValue("destuffingType",destuffingtype)
  setValue("cargoDimensionUnit",rec.cargoDimensionUnit==undefined?"Cms":rec.cargoDimensionUnit)
  setValue("isFba",rec.fba === "Yes" ? true :false)
  setValue("ior",rec.fbaIorRequired === "Yes" ? true :false)
  setValue("occ",rec.fbaOCC === "Yes" ? true :false)
  setValue("dcc",rec.fbaDCC === "Yes" ? true :false)
  setValue("survey",rec.fbaSurvey === "Yes" ? true :false)
  setValue("storageAtDestination",rec.fbaSAD === "Yes" ? true :false)
  setValue("palletizationandlabelling",rec.fbaPAL === "Yes" ? true :false)
  setValue("days",rec.fbaDays!=undefined?rec.fbaDays:0)
  setValue("pick_up_by_amazon",rec.pick_up_by_amazon === "Yes" ? true :false)
  setValue("containerDetails",rec.containerDetails)
  if(rec.multidest!=undefined){
    let m = watch("multidest")
    if(JSON.stringify(rec.multidest)!=JSON.stringify(watch("multidest")) && m!=undefined){
      setValue("multidest",rec.multidest)
    }
  }
  }
  setShow(true)
},[rec])
if(quoteDataRec.shipmentType.toLowerCase().indexOf("fcl") != -1 && quoteDataRec.emptycontainerweight == undefined){
  quoteDataRec.emptycontainerweight = 'yes'
}

const setdata = async (data) =>{
  //quoteDataRec.multidest = data.multidest
  for (let i = 2; i < 6; i++) {
    delete quoteDataRec['destination'+i]
  }
  for (let i = 0; i < data.multidest.length; i++) {
    if(i==0){
      quoteDataRec.destination = data.multidest[i]['destination']
    }else{
        let k = i+1
        quoteDataRec['destination'+k] = data.multidest[i]['destination']
    }
  }
  quoteDataRec.nonStackable = data.nonStackable ? 'Yes' : "No"
  quoteDataRec.origin = data.origin
  quoteDataRec.shipmentScope = data.scope
  quoteDataRec.customerReferenceNum = data.customerreference
  quoteDataRec.hazardous = data.hazardous ? 'Yes' : "No"
  quoteDataRec.temperatureControlled = data.temperatureControlled ? 'Yes' : "No"
  quoteDataRec.cargoDimensionUnit = data.cargoDimensionUnit;
  quoteDataRec.unnumber = data.unnumber;
  quoteDataRec.packingGroup = data.packingGroup
  quoteDataRec.quoteClass = data.quoteClass
  quoteDataRec.stuffingType = data.stuffingType
  quoteDataRec.destuffingType = data.destuffingType
  quoteDataRec.minTemperature = data.minTemperature
  quoteDataRec.maxTemperature = data.maxTemperature
  quoteDataRec.temperature = data.temperature
  quoteDataRec.cargoValue = data.cargoValue;
  quoteDataRec.fba = data.isFba ? "Yes" : "No";
  quoteDataRec.fbaIorRequired = data.ior ? "Yes" : "No";
  quoteDataRec.fbaOCC = data.occ ? "Yes" : "No";
  quoteDataRec.fbaDCC = data.dcc ? "Yes" : "No";
  quoteDataRec.fbaPAL = data.palletizationandlabelling ? "Yes" : "No";
  quoteDataRec.fbaSAD = data.storageAtDestination ? "Yes" : "No";
  quoteDataRec.fbaSurvey = data.survey ? "Yes" : "No";
  quoteDataRec.pick_up_by_amazon = data.pick_up_by_amazon ? "Yes" : "No";
  quoteDataRec.fbaDays = data.days!=undefined? data.days: 0 ;
}
const checkcontainer = (containerDetails)=>{
  let k = {}
  let l = true
  for (let j = 0; j < containerDetails.length; j++) {
      if(containerDetails[j]['containerType']!=undefined && containerDetails[j]['containerType'].length>0){
          let c = containerDetails[j]['containerType']
          if(k[c]==undefined){
          k[c] = Number(containerDetails[j]['wtPerContainer'])
          }
          if(k[c]!=Number(containerDetails[j]['wtPerContainer'])){
          configStore.setModalMessage(
              `Different Container Weight Not Allowed (${containerDetails[j]['containerType']})`
          )
          l=false
          }
      }
  }
  return l
}
const onSave = async (data,e)=>{
  if(checkcontainer(multidest)){
    await setdata(data)
    chargesValidationStore.onSaveBtnClicked();
    configStore.quoteCurrentView = "list";
    configStore.modalVisible = false;
    configStore.isDisable = true
    Api.saveQuote1("DRAFT(Enquiry)");
    AgmodalStore.pageVisible = false;
    AgmodalStore.apiCall = true
  }
}

const onSubmit = async (data, e) => {
  if(checkcontainer(multidest)){
  await setdata(data)
  chargesValidationStore.onSaveBtnClicked();
  if(quoteDataRec.entityId === ""){
    return configStore.setModalMessage(
        `Please select the customer`
      );
  }

  if (quoteDataRec.shipmentType.toLowerCase().includes("lcl")) {
    if (cargoStore.cargoList.length < 0) {
      configStore.setModalMessage(
        `Please add atleast one cargo Details`
      );
    } else if (quoteDataRec.consolidatorList.length < 1) {
      configStore.setModalMessage(
        `Please check the consolidator fields`
      );
    } else {
      if (QuotationValidationStore.isValid()){
            if(quoteDataRec.status == 'DRAFT(Enquiry)'){
             
              configStore.quoteCurrentView = "list";
              configStore.modalVisible = false;
              configStore.isDisable = true
              Api.saveQuote2("DRAFT")
            }else{
              configStore.isDisable = true
              Api.saveQuote("DRAFT")
            }
          }else{
        configStore.setModalMessage(
          QuotationValidationStore.message
        );
          }
    }
    return;
  }
  
   else {
    console.log(QuotationValidationStore.quotationValidMap.portOfLoading, "QuotationValidationStore");
    if (QuotationValidationStore.isValid()){ 
          if(quoteDataRec.status == 'DRAFT(Enquiry)'){
           
            configStore.quoteCurrentView = "list";
            configStore.modalVisible = false;
            configStore.isDisable = true
            Api.saveQuote2("DRAFT")
          }else{
            configStore.isDisable = true
            Api.saveQuote("DRAFT");
          }
        }else{
      configStore.setModalMessage(
        QuotationValidationStore.message
      );
        }
  }
  return;
}
}

  return (
    <>
    {show?
    <>
    <div>
        <Headspan color="#555555" fontSize= "20px" fontWeight="700" data={quoteDataRec.quotationNum!=undefined && quoteDataRec.quotationNum.length>0?"Quotation Ref. No# ":"Quotation"} />
        {(quoteDataRec.quotationNum!=undefined && quoteDataRec.quotationNum.length>0)?<Headspan color="#2C358A" fontSize= "20px" fontWeight="700" data={quoteDataRec.quotationNum} />:""}
      </div>
      <div class="columns is-vcentered is-multiline my-1">
      <Col4>
      {
                  (quoteDataRec.isfromAgragaEnquiry == 'Yes') ? 
                  <AgCustlist
                {..._params("* Customer", "entityId", "select",entitieslist)}
                isValid={QuotationValidationStore.quotationValidMap["entityId"]}
                validationMsg="- Please select one of customers."
                isDisabled={
                  quoteDataRec.quotationNum.indexOf("TBA") == -1 ? { disabled: true } : {}
                }
                callback={(entityId) => {
                  QuotationValidationStore.validate("entityId");
                  let entity = EntityV2Store.Cuslist.filter((e) => e.entityId === entityId);
                  console.log("FOUND ENTITY = ", entity);
                  if (entity.length > 0) {
                    let k = []
                    let email = entity[0].customer?.crossBorder?.relationshipManager;
                    quoteDataRec.entityId = entityId;
                    quoteDataRec.entityName = entity[0].entityName;
                    if(email?.length > 0){
                      k.push(aguserStore.getdetails(email))
                      quoteDataRec.salesperson = aguserStore.getName4email(email);
                    }else {
                      quoteDataRec.salesperson = "";
                    }
                    email = entity[0].customer?.crossBorder?.customerService;
                        if (email?.length > 0){
                          k.push(aguserStore.getdetails(email))
                      quoteDataRec.customerService = aguserStore.getName4email(email);
                        }else {
                      quoteDataRec.customerService = "";
                    }
                    addprlist(k)
                  }
                }}
              /> :
              <AgCustlist
                {..._params("* Customer", "entityId", "select", entitieslist)}
                isValid={QuotationValidationStore.quotationValidMap["entityId"]}
                validationMsg="- Please select one of customers."
                isDisabled={
                  quoteDataRec.quotationNum !== "" ? { disabled: true } : {}
                }
                callback={(entityId) => {
                  QuotationValidationStore.validate("entityId");
                  let entity = EntityV2Store.Cuslist.filter((e) => e.entityId === entityId);
                  console.log("FOUND ENTITY = ", entity);
                  if (entity.length > 0) {
                    let k = []
                    let email = entity[0].customer?.crossBorder?.relationshipManager;
                    quoteDataRec.entityId = entityId;
                    quoteDataRec.entityName = entity[0].entityName;
                    if(email?.length > 0){
                      k.push(aguserStore.getdetails(email))
                      quoteDataRec.salesperson = aguserStore.getName4email(email);
                    }else {
                      quoteDataRec.salesperson = "";
                    }
                    email = entity[0].customer?.crossBorder?.customerService;
                        if (email?.length > 0){
                          k.push(aguserStore.getdetails(email))
                      quoteDataRec.customerService = aguserStore.getName4email(email);
                        }else {
                      quoteDataRec.customerService = "";
                    }
                    addprlist(k)
                  }
                }}
              />
}
      </Col4>
      </div>
           

      <ActiveTab callback={changeShipmentTypes} viewonly={quoteDataRec.quotationNum !== "" || configStore.disableShipmentType !== ""?true:false} selectedType={selectedType}/>
      <div noValidate style={{width:"100%",display:"flex",flexDirection:"column"}} >
       <div className="flex flex-row gap-[10px]">
          
       <Route form={cargoForm} viewonly={false}/>
       <Details form={cargoForm} viewonly={false}/> 
    
       </div>
       <hr />
      
            {/* <Col3>
              <h1 className="title">Quotation</h1>
              {displayReferenceNumber()}
            </Col3> */}
            <Card className="qoutecard">
                        <div style={{display:"flex",paddingLeft:"15px"}}>
                            <span><img src={ship1}/></span><Headspan color="#00000" fontSize= "14px" fontWeight="900" data=" Additional Details"/>
                        </div>
                        
                    
            <div class="columns is-vcentered is-multiline m-1">
            
            <Col3>
            <AgSelectNew
                  {..._params(
                    "Sales Person",
                    "salesperson",
                    "select",
                    agActiveList
                  )}
                />
          </Col3>
          <Col3>
            <AgInput
              {..._params("* Valid Upto", "validUpto", "date")}
              isValid={QuotationValidationStore.quotationValidMap["validUpto"]}
              callback={() => QuotationValidationStore.validate("validUpto")}
              validationMsg="- Please pick valid data."
            />
          </Col3>
          <Col3>
            {
              (quoteDataRec.hazardous == 'Yes') ? 
              <AgSelect
              {..._params("Commodity Type", "commodityType", "select", hazardousList)}
            />
              :
              <AgSelect
              {..._params("Commodity Type", "commodityType", "select", commodityList)}
            />
            }
            
          </Col3>
          <Col3>
            <AgSelect
              {..._params("Incoterms", "incoTerms", "select", incoTermsList)}
            />
          </Col3>
          <Col3>
          <AgUnloc
              {..._params(portOfReceiptLable, "portOfReceipt")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfReceipt"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfReceipt");
                QuotationValidationStore.quotationValidMap.portOfReceipt = list.some(ele => ele === value);
              }}
            />
            
          </Col3>
          <Col3>
            <AgUnloc
              {..._params(portOfLoadingLabel, "portOfLoading")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfLoading"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfLoading");
                QuotationValidationStore.quotationValidMap.portOfLoading = list.some(ele => ele === value);
              }}
            />
          </Col3>
          <Col3>
            <AgUnloc
              {..._params(portOfDischargeLabel, "portOfDischarge")}
              isValid={
                QuotationValidationStore.quotationValidMap["portOfDischarge"]
              }
              callback={(value, list) => {
                QuotationValidationStore.validate("portOfDischarge");
                QuotationValidationStore.quotationValidMap.portOfDischarge = list.some(ele => ele === value);
              }}
            />
          </Col3>
            <Col3>
              <AgUnloc
                {..._params(
                  "* Final Place of Delivery",
                  "finalPlaceOfDelivery"
                )}
                isValid={
                  QuotationValidationStore.quotationValidMap[
                  "finalPlaceOfDelivery"
                  ]
                }
                callback={(value, list) => {
                  QuotationValidationStore.validate("finalPlaceOfDelivery");
                  QuotationValidationStore.quotationValidMap.finalPlaceOfDelivery = list.some(ele => ele === value);
                }}
                validationMsg="- Please select enter valid UN location."
              />
            </Col3>
            
          {quoteDataRec.shipmentType
            .toString()
            .toLowerCase()
            .includes("fcl") && (
              <Col3>
                <AgSelectWithMap
                  label={"* Carrier"}
                  record={quoteDataRec}
                  name="carrier"
                  dataMap={dataListStore["FCLCarrierList"].map((carrier) => ({
                    key: carrier["Display Name"],
                    value: `${carrier["Display Name"]} (${carrier["SCAC code"]})`,
                  }))}
                  isValid={QuotationValidationStore.quotationValidMap["carrier"]}
                  callback={() => QuotationValidationStore.validate("carrier")}
                  validationMsg="- Please pick valid carrier."
                />
              </Col3>
            )}

          {quoteDataRec.shipmentType
            .toString()
            .toLowerCase()
            .includes("lcl") && (
              <Col3>
                <AgMultiSelectWithCheckBoxes
                  label={"Consolidator"}
                  storeKey={consolidatorListKey}
                  callback={() => {
                    quoteDataRec.consolidatorList =
                      multiSelectListStore.selectList[consolidatorListKey]
                        .filter(({ checked }) => checked)
                        .map(({ id }) =>
                          selectedConsolidatorList.find(
                            ({ consolidatorAbbrName }) =>
                              consolidatorAbbrName === id
                          )
                        );
                  }}
                  withAllShortcut></AgMultiSelectWithCheckBoxes>
                {quoteDataRec?.consolidatorList?.length < 1 && <p class="help is-danger"> {`* Please select atleast one consolidator`}</p>}
              </Col3>
            )}

<Col3>
            <AgInput
              {..._params("* Tentative transit Time", "transitTime", "number")}
              isValid={
                QuotationValidationStore.quotationValidMap["transitTime"]
              }
              callback={() => QuotationValidationStore.validate("transitTime")}
              validationMsg="- Please enter valid transit time."
            />
          </Col3>
          {(quoteDataRec.shipmentType.indexOf("Air") != -1) ? 
          <Col3>
            <AgAirline
                label="* Airline"
                record={quoteDataRec}
                name="airline"
                isValid={QuotationValidationStore.quotationValidMap["airline"]}
                callback={() => QuotationValidationStore.validate("airline")}
                validationMsg="- Please select one airline."
              />
              
            </Col3>:""}
            <Col3>
                <AgSelectNew
                  {..._params(
                    "* Procurement",
                    "procurement",
                    "select",
                    prListglobal
                  )}
                  isValid={
                    QuotationValidationStore.quotationValidMap["procurement"]
                  }
                  callback={() =>
                    QuotationValidationStore.validate("procurement")
                  }
                  validationMsg="- Please select valid procurement."
                />
              </Col3>
            </div>
            </Card>
        <VehicleViewv3 vehicleDetails={quoteDataRec.vehicleDetails} />

        {quoteDataRec.shipmentType.indexOf("LCL") >= 0 ? (
          <LCLChargesCompv3 />
        ) : (
          <></>
        )}
        {quoteDataRec.shipmentType.indexOf("Air") >= 0 ? (
          <AirChargesCompv3 />
        ) : (
          <></>
        )}
        {quoteDataRec.shipmentType.indexOf("FCL") >= 0 ? (
          <FCLChargesCompv3 />
        ) : (
          <></>
        )}
        <Columns>
          <Col12>
            <h3 className="title is-5">Custom Terms & Conditions</h3>
            {/* <textarea value={quoteDataRec.customTC}
                            onChange={(e) => quoteDataRec.customTC = e.target.value}
                            class="textarea" placeholder=""></textarea> */}
            <textarea
              ref={ref}
              value={quoteDataRec.customTC}
              onChange={handleChange}
              class="textarea"
              placeholder=""
            />
          </Col12>
        </Columns>
        <Columns>
          <Col12>
            <div className="field is-grouped" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {
                  (quoteDataRec.status == 'DRAFT(Enquiry)' && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.PROCUREMENT_ONLY_SUBMIT)) ?
                  <div className="control">
                  <button
                    className="button is-success"
                    disabled={configStore.isDisable} 
                    onClick={async (e)=>{
                      e.nativeEvent.preventDefault()
                  e.preventDefault()
                  e.nativeEvent.stopPropagation()
                  e.stopPropagation()
                  const isValid = await trigger();
                  console.log("+++++++++++++++++45",isValid)
    
  if (isValid) {
    handleSubmit(onSave)();
  }else{
    configStore.setModalMessage(
      `Please check the fields`
    )
  }
                    }}>
                    Save 
                  </button>
                </div> : <></>
                }
              <div className="control">
                
                <button
                  className="button is-link"
                  disabled={configStore.isDisable}
                  onClick={async (e)=>{
                    e.nativeEvent.preventDefault()
                e.preventDefault()
                e.nativeEvent.stopPropagation()
                e.stopPropagation()
                const isValid = await trigger();
                console.log("+++++++++++++++++45",isValid)
    
  if (isValid) {
    handleSubmit(onSubmit)();
  }else{
    QuotationValidationStore.isValid()
    configStore.setModalMessage(
      `Please check the fields`
    )
  }
                  }}>
                  {buttontext}
                </button>
              </div>
              <div className="control">
                <button class="button is-danger" onClick={() => {
                  AgmodalStore.pageVisible = false;
                  AgmodalStore.apiCall = true;
                }}>
                  Cancel
                </button>
              </div>
            </div>
          </Col12>
        </Columns>
        </div></>
      :""}
      
    </>
  );
}
