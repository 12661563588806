import React, { useState, useEffect } from "react";
import { Col6, Cols } from "../Controls";
import { configStore } from "../state/CommonState";
import MonApi from "../state/mongo-api";

export default function VisibilityExceptionsConfig() {
    const [inputValue, setInputValue] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [fields, setFields] = useState([]);  // State for all fields
    const [loading, setLoading] = useState(true);  // State for loading status

    // Function to fetch all fields from API
    const fetchFields = async () => {
        try {
            const url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/visibility/exceptions`;
            const response = await MonApi.apost(url, {});

            if (response && response.allFields) {
                setFields(response.allFields);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching fields:", error);
            setLoading(false);
        }
    };

    // useEffect to fetch fields when component mounts
    useEffect(() => {
        fetchFields();
    }, []);

    const handleSave = async () => {
        if (inputValue.length < 3) {
            setIsInvalid(true); // Highlight input if invalid
            return;
        }

        setIsInvalid(false);

        try {
            const url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/visibility/exceptions`;
            const ret = await MonApi.apost(url, { field: inputValue });

            // Assuming ret contains a success message and status
            configStore.setModalMessage(ret?.message);
            setInputValue("");
            fetchFields(); // Re-fetch fields after save
        } catch (error) {
            console.log(error);
            configStore.setModalMessage('Failed to save field. Please try again.');
        }
    };

    return (
        <>
        <Cols style={{ fontFamily: 'Verdana, sans-serif', textAlign: 'center' }}></Cols>
        <Cols>
            <Col6>Add new field for visibility exceptions and alerts</Col6>
            <Col6></Col6>
        </Cols>
        <Cols>
            <Col6>
                <input
                    type="text"
                    className={`input is-small ${isInvalid ? "is-invalid" : ""}`}
                    maxLength={25}
                    placeholder="Enter text or numbers"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setIsInvalid(false);
                    }}
                />
            </Col6>
            <Col6>
                <button className="button is-link" onClick={handleSave}>
                    Save
                </button>
            </Col6>
        </Cols>
    
        <Cols style={{ paddingTop: '10px' }}>
            <Col6>
                <h3>Existing Visibility Exceptions</h3>
            </Col6>
        </Cols>
        <Cols>
            {loading ? (
                <Col6>Loading...</Col6>
            ) : (
                <Col6
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        border: '2px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        width: '100%', // Full width
                    }}
                >
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)', // Two columns
                            gap: '10px', // Space between items
                        }}
                    >
                        {fields.map((field, index) => (
                            <div
                                key={index}
                                style={{
                                    padding: '5px',
                                    border: '1px solid #ddd',
                                    borderRadius: '3px',
                                    backgroundColor: '#f9f9f9',
                                    textAlign: 'left',
                                }}
                            >
                                {field}
                            </div>
                        ))}
                    </div>
                </Col6>
            )}
        </Cols>
    
        <style>
            {`
            .is-invalid {
                border: 2px solid red;
            }
            `}
        </style>
    </>
    
    );
}
